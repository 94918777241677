<template>
    <v-container fill-height fluid text-xs-center class="pa-0">
        <v-card class="fill-height container--fluid" width="100vw" flat tile>
            <v-row justify="start">
                <v-col cols="12">
                    <v-card class="d-flex fill-height d-flex fluid pa-0 ma-0" flat tile width="100vw">
                        <v-scroll-x-transition appear>
                            <v-img
                                    src="@/assets/images/bg-breadcrumbs.jpg"
                                    transition="slide-x-transition"
                                    position="center"
                                    width="100%"
                                    height="200"
                                    max-height="200">
                                <v-container
                                        fluid
                                        class="fill-height align-end">
                                    <v-row align="end" justify="start">
                                        <v-col cols="12">
                                            <div class="white--text">
                                                <span class="white--text pl-5 text-h4 text-md-h3 font-weight-black">
                                                    Manténte informado...
                                                </span>
                                            </div>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-breadcrumbs dark :items="itemsBreadcrumbs">
                                                <template v-slot:divider>
                                                    <v-icon>mdi-arrow-right</v-icon>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <v-breadcrumbs-item
                                                            :href="item.href"
                                                            :disabled="item.disabled">
                                                        <span class="white--text">
                                                            {{ item.text }}
                                                        </span>
                                                    </v-breadcrumbs-item>
                                                </template>
                                            </v-breadcrumbs>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-img>
                        </v-scroll-x-transition>
                    </v-card>
                </v-col>
                <v-col cols="12" class="py-0">
                    <div class="py-0">
                        <v-toolbar tile flat>
                            <v-btn icon v-on:click="goBack">
                                <v-icon>mdi-arrow-left</v-icon>
                            </v-btn>
                            <v-toolbar-title>Ir atrás</v-toolbar-title>
                        </v-toolbar>
                    </div>
                </v-col>
                <v-col cols="12" class="pt-0 align-center justify-center">
                    <v-card class="fill-height container--fluid mx-0 mb-5" width="100vw" flat tile>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" md="8">
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-card outlined>
                                                    <!--class="d-flex flex-no-wrap justify-space-between"-->
                                                    <div>
                                                        <v-breadcrumbs class="pl-4 py-1" :items="postData.categories">
                                                            <template v-slot:item="{ item }">
                                                                <v-breadcrumbs-item
                                                                        href="/blogs"
                                                                        :disabled="true">
                                                                <span class="blue--text">
                                                                    {{ item.name }}
                                                                </span>
                                                                </v-breadcrumbs-item>
                                                            </template>
                                                        </v-breadcrumbs>
                                                        <v-card-title
                                                                class="text-h5 pt-0 pb-0"
                                                                v-text="postData.title">
                                                        </v-card-title>
                                                        <v-card-text class="pb-1">
                                                            <div class="pt-0 pb-0">
                                                                <v-icon small dense color="grey lighten-1">
                                                                    mdi-account
                                                                </v-icon>
                                                                <span class="text-caption">
                                                                    {{ postData.author | trimText }}
                                                                </span>
                                                                    <v-icon small dense color="grey lighten-1">
                                                                        mdi-calendar-blank
                                                                    </v-icon>
                                                                <span class="text-caption">
                                                                    {{ postData.created_at | formatDate }}
                                                                </span>
                                                            </div>
                                                        </v-card-text>
                                                        <v-container fluid class="mt-0 pt-0">
                                                            <v-card flat tile class="container--fluid fill-height">
                                                                <v-img
                                                                        :src="postData.pictures[0] != null ? postData.pictures[0].url : require('@/assets/images/no-image.jpg')"
                                                                        :lazy-src="require('@/assets/images/no-image.jpg')"
                                                                        max-height="500"
                                                                        aspect-ratio="1"
                                                                        class="white--text align-center justify-center elevation-0">
                                                                </v-img>
                                                                <div class="text-center">
                                                                    <v-card-text class="text-pre-line text-caption pt-0 pl-2" v-html="postData.caption_image"></v-card-text>
                                                                </div>
                                                                <v-card flat class="container--fluid fill-height pt-0 text-pre-line overflow-y-auto" max-height="200">
                                                                    <div>
                                                                        <v-card-text class="text-pre-wrap pt-0 pl-2" v-html="postData.content"></v-card-text>
                                                                    </div>
                                                                    <div v-show="false">
                                                                        <div v-if="postData.categories" >
                                                                            <v-chip small color="lime" v-for="(cat, i) in postData.categories" :key="i" class="mr-1">
                                                                                {{ cat.name }}
                                                                            </v-chip>
                                                                        </div>
                                                                    </div>
                                                                </v-card>
                                                            </v-card>
                                                        </v-container>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-container class="max-width" v-show="false">
                                                <v-pagination
                                                        v-model="page"
                                                        class="my-0"
                                                        :length="15"
                                                        :total-visible="7"
                                                ></v-pagination>
                                            </v-container>
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-card flat rounded="false" class="mr-2">
                                                    <v-card-title class="pa-1 blue darken-1">
                                                        <span class="text-subtitle-1 white--text">Buscar Blog</span>
                                                    </v-card-title>
                                                    <div class="mt-1">
                                                        <v-text-field
                                                                clearable
                                                                dense
                                                                flat
                                                                hide-details
                                                                id="findtext-blog"
                                                                label="Buscar blog..."
                                                                name="find"
                                                                outlined
                                                                placeholder="Buscar blog..."
                                                                prepend-inner-icon="mdi-magnify"
                                                                single-line>
                                                        </v-text-field>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-card flat rounded="false" class="mr-2">
                                                    <v-card-title class="pa-1 blue darken-1">
                                                        <span class="text-subtitle-1 white--text">Posts Recientes</span>
                                                    </v-card-title>
                                                    <div class="mt-1">
                                                        <v-list outlined flat>
                                                            <v-list-item-group
                                                                    color="primary">
                                                                <template v-for="(item, index) in recentPosts">
                                                                    <v-list-item :key="index">
                                                                        <v-list-item-icon class="mr-0">
                                                                            <v-icon>
                                                                                mdi-newspaper
                                                                            </v-icon>
                                                                        </v-list-item-icon>
                                                                        <v-list-item-action @click="showPost(item.id)">
                                                                            <v-list-item-title v-html="item.title" @onclick="showPost(item.index)"></v-list-item-title>
                                                                        </v-list-item-action>
                                                                    </v-list-item>
                                                                </template>
                                                            </v-list-item-group>
                                                        </v-list>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                            <v-col col="12">
                                                <v-card flat rounded="false" class="mr-2">
                                                    <v-card-title class="pa-1 blue darken-1">
                                                        <span class="text-subtitle-1 white--text">Etiquetas</span>
                                                    </v-card-title>
                                                    <div class="mt-2">
                                                        <v-chip v-for="(cat, i) in categoriesList" :key="i" class="mr-1 mt-1">
                                                            {{ cat.name }}
                                                        </v-chip>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-card outlined>
                                                    <v-date-picker
                                                            full-width
                                                            flat
                                                            no-title
                                                            locale="es"
                                                            show-current="false"
                                                            v-model="postData.created_at"
                                                            readonly>
                                                    </v-date-picker>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <v-snackbar
                v-model="snackBar"
                bottom
                :timeout="3000">
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn  v-bind="attrs"
                        icon
                        @click="snackBar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
    import blogsService from "@/providers/BlogsService";
    import blogsCatService from '@/providers/BlogsCategoriesService';
    import moment from 'moment';
    
    export default {
        name: "BlogPostComponent",
        data: () => ({
            page: 1,
            valid: false,
            snackBar: false,
            snackText: '',
            searchBlog: '',
            categoriesList: [],
            recentPosts: [],
            postData: {
                id: 0,
                title: '',
                content: '',
                caption_image: '',
                author: '',
                created_at: null,
                pictures: [],
                categories: []
            },
            tagBreadcrumbs: [],
            tagBreadcrumb: {
                text: '',
                disabled: true,
                href: '/blogs',
            },
            itemsBreadcrumbs: [
                {
                    text: 'Home',
                    disabled: false,
                    href: '/homepage',
                },
                {
                    text: 'Blogs',
                    disabled: false,
                    href: '/blogs',
                },
                {
                    text: 'Post',
                    disabled: true,
                    href: '/blog-post',
                }
            ],
        }),
        filters: {
            formatDate (value) {
                if (value) {
                    return moment(String(value)).locale('es').format('MMM D, YYYY [a las] hh:mm a');
                }
            },
            initials(value) {
                const name = value.split(' ');
                return `${name[0].charAt(0).toUpperCase()}${name[1] ? name[1].charAt(0).toUpperCase() : ''}`;
            },
            trimText(value) {
                const name = value.trim();
                return name;
            }
        },
        methods: {
            dateToString(value) {
                if (value) {
                    return moment(String(value)).locale('es').format('YYYY-MM-DD');
                } else {
                    return '';
                }
            },
            goBack() {
                this.$router.push('/blogs');
            },
            toTop() {
                this.$vuetify.goTo(0);
            },
            getCategories() {
                // Nomenclador de Categorias
                blogsCatService.getAllRecords().then(record => {
                    this.categoriesList = record.value;
                    // console.log("Categorías: ", this.categoriesList);
                });
            },
            getPostRecents() {
                // Registro de Blogs...
                blogsService.getViewListRecents().then(record => {
                    this.recentPosts = record.value;
                    // console.log("Blogs Recientes: ", this.recentPosts);
                });
            },
            getPost(idBlog) {
                let record = null;
                blogsService.getRecord(idBlog).then( fetch_data => {
                    record = fetch_data.value;
                    // console.log('data:', record);
                    this.postData.id = record.id;
                    this.postData.title = record.title;
                    this.postData.content = record.content;
                    this.postData.author = record.author;
                    this.postData.caption_image = record.caption_image;
                    this.postData.created_at = this.dateToString(record.created_at);
                    this.postData.categories = record.categories;
                    let tags = record.categories;
                    // console.log('tags:', tags);
                    tags.forEach(item => {
                        this.tagBreadcrumb.text = item.name;
                        // console.log('tagBreadcrumb.text: ', this.tagBreadcrumb.text);
                        this.tagBreadcrumbs.push(this.tagBreadcrumbs);
                    });
                    // console.log('tagBreadcrumbs:', this.tagBreadcrumbs);
                    this.postData.pictures = record.pictures;
                    // let date = this.dateToString(record.created_at);
                    // console.log('date:', date);
                    this.toTop();
                });
            },
            showPost(idBlog) {
                this.selectedId = idBlog;
                this.getPost(idBlog);
            }
        },
        mounted() {
            this.tagBreadcrumbs = [];
            this.getCategories();
            this.getPostRecents();
            this.getPost(this.$route.params.id);
        },
    }
</script>

<style scoped>
    .bottom-gradient {
        background-image: linear-gradient(
                to bottom,
                rgba(163, 153, 248, 0.295) 0%,
                rgba(101, 99, 248, 0.699) 100%
        );
    }
    .blend-opacity {
        opacity: 0.1;
        transition: opacity 0.4s ease-in-out;
        background-color: black;
        background-blend-mode: normal;
    }
    .boxdivider {
        background-color: transparent;
        width: 100px;
        height: 20px;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom-width: 3px;
        border-bottom-color: blueviolet;
        border-bottom-style: solid;
        display: block;
    }
</style>